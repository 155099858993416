<template>
	<div class="bpb-switch">
		<label v-if="label" for="" class="bpb-switch-label">{{ label }}</label>
		<button class="bpb-switch-core" type="button">
			<input :ref="ref" type="checkbox" class="bpb-switch-checkbox" :name="name" :id="id" :checked="checked" @change="inputChange($event)" @click="emitir" :readonly="readonly" :disabled="disabled">
			<span v-if="texton && checked" class="bpb-switch-text bpb-switch-text-on">{{ texton }}</span>
			<span v-if="textoff && !checked" class="bpb-switch-text bpb-switch-text-off">{{ textoff }}</span>
			<span class="bpb-switch-circle"></span>
		</button>
	</div>
</template>

<script type="text/javascript">
	export default {
		props: {
			value: null
			,id: null
			,name: null
			,label: null
			,type: null
			,typeoff: null
			,textoff: null
			,texton: null
			,readonly: false
			,disabled: false
		}
		,model: {
			prop: 'value'
			,event: 'checked'
		}
		,data: function() {
			return {
				ref: null
				,tipos: ['primary','success','warning','danger','info','secondary','dark','light']
				,checked: false
			}
		}
		,created: function() {
			if (!this.ref)
				this.ref = 'bpbswitch'+this.$helper.strRand(8);
		}
		,mounted: function() {
			let result = typeof(this.value) != 'undefined' && [1,'1',true].indexOf(this.value) > -1;
			// this.$log.info(this.id, result);
			this.checked = result;
			
			if (this.value)
				this.$refs[this.ref].value = this.value;

			if (this.typeoff) {
				if (this.tipos.indexOf(this.typeoff) == -1)
					throw new Error('El tipo typeoff no es válido');

				this.$refs[this.ref].parentNode.classList.add('bpb-switch-type-'+this.typeoff);
			}
		}
		,methods: {
			inputChange: function(e) {
				this.checked = e.target.checked ? 1 : 0;
			}
			,emitir: function(e) {
				this.$emit('checked',e.target.checked ? 1 : 0, this.value, this.id, e.target);
			}
		}
		,watch: {
			checked: function(value) {
				// this.$log.info('checked cambio!')
				let tipo = this.tipos.indexOf(this.type) > -1 ? this.type : 'primary';
				let input = this.$refs[this.ref];

				if (value) {
					if (this.typeoff) {
						let tipoof = this.tipos.indexOf(this.typeoff) > -1 ? this.typeoff : null;

						if (tipoof) {
							input.parentNode.classList.remove('bpb-switch-type-'+tipoof);
						}
					}
					
					input.parentNode.classList.add('bpb-switch-active');
					input.parentNode.classList.add('bpb-switch-type-'+tipo);
					// this.checked = input.checked;
				}else {
					input.parentNode.classList.remove('bpb-switch-active');
					input.parentNode.classList.remove('bpb-switch-type-'+tipo);

					if (this.typeoff) {
						tipo = this.tipos.indexOf(this.typeoff) > -1 ? this.typeoff : null;

						if (tipo) {
							input.parentNode.classList.add('bpb-switch-type-'+tipo);
						}
					}
				}
			}
			,value: function(val) {
				this.checked = typeof(this.value) != 'undefined' && [1,'1',true].indexOf(this.value) > -1;
			}
		}
	}
</script>

<style lang="scss">
	.bpb-switch {
		.bpb-switch-core {
			min-width: 50px;
			height: 26px;
			background-color: #D9D9D9;
			border: 0px;
			border-radius: 17px;
			position: relative;
			display: inline-block;
			transition-property: all;
			transition-duration: 0.25s;
			transition-timing-function:ease;
			transition-delay: 0s;

			input[type="checkbox"].bpb-switch-checkbox {
				width: 100%;
				height: 100%;
				opacity: 0;
				cursor: pointer;
				display: inline-block;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 200;
			}

			span.bpb-switch-text {
				font-size: 0.7em;
				font-weight: bold;
				color: #8C8C8C;
				display: inline-block;
				margin-top: 5px;
				transition-property: all;
				transition-duration: 0.25s;
				transition-timing-function:ease;
				transition-delay: 0s;
			}

			span.bpb-switch-text-off {
				margin-left: 25px;
				margin-right: 3px;
				// left: calc(100% - 26px);
			}

			span.bpb-switch-text-on {
				margin-left: 3px;
				margin-right: 25px;
				opacity: 0;
			}

			span.bpb-switch-circle {
				width: 20px;
				height: 20px;
				background-color: #fff;
				border-radius: 50%;
				display: block;
				position: absolute;
				top: 3px;
				left: 3px;

				transition-property: all;
				transition-duration: 0.25s;
				transition-timing-function:ease;
				transition-delay: 0s;
			}
		}

		label.bpb-switch-label {
			margin-right: 10px;
		}

		.bpb-switch-active {
			span.bpb-switch-text-off {
				opacity: 0;
			}
			span.bpb-switch-text-on {
				opacity: 1;
			}

			span.bpb-switch-circle {
				margin-left: calc(100% - 26px);
			}
		}

		.bpb-switch-type-primary {
			background-color: #0781cd;

			span.bpb-switch-text {
				color: #fff;
			}
		}

		.bpb-switch-type-success {
			background-color: #55d775;

			span.bpb-switch-text {
				color: #fff;
			}
		}

		.bpb-switch-type-warning {
			background-color: #f99f43;

			span.bpb-switch-text {
				color: #A0591F;
			}
		}

		.bpb-switch-type-danger {
			background-color: #ea5455;

			span.bpb-switch-text {
				color: #fff;
			}
		}

		.bpb-switch-type-info {
			background-color: #17a2b8;

			span.bpb-switch-text {
				color: #fff;
			}
		}

		.bpb-switch-type-secondary {
			background-color: #6c757d;

			span.bpb-switch-text {
				color: #fff;
			}
		}

		.bpb-switch-type-dark {
			background-color: #343a40;

			span.bpb-switch-text {
				color: #fff;
			}
		}

		.bpb-switch-type-light {
			background-color: #fff;
			border: solid 1px #B3B3B3;

			span.bpb-switch-circle {
				top: 2px;
				border: solid 1px #B3B3B3;
			}
		}
	}


</style>