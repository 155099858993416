import Vue from 'vue'
import store from '@/store'
import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_GARANTIAS);

class Portal {
	obtener_garantias(options) {
		return http.get(utils.url_options('/portal_admin',options));
	}

	obtener_garantia(id) {
		return http.get(`/portal_admin/${id}`);
	}

	guardar_garantia(id, payload) {
		return http.put(`/portal_admin/${id}`,payload);
	}

	cargar_imagen(id, payload) {
		return http.post(`/portal_admin/${id}/imagen`, payload);
	}

	editar_imagen(id, payload) {
		return http.put(`/portal_admin/${id}/imagen`, payload);
	}

	descargar_imagen(id) {
		return http.get(`/portal_admin/imagen/${id}`,{responseType: 'blob'});
	}

	importar_imagenes(id) {
		return http.get(`/portal_admin/imagen/${id}/importar`);
	}

	eliminar_imagen(id) {
		return http.delete(`/portal_admin/imagen/${id}`);
	}

	listar_estados(options) {
		return http.get(utils.url_options(`/portal_admin/estados`,options));
	}
}

export default new Portal();