<template>
	<div class="col-sm-12">
		<div class="row mb-4">
			<label for="solicitud_id" class="col-form-label col-sm-1 offset-sm-4">Solicitud ID</label>
			<div class="col-sm-2"><input v-model="info_garantia.solicitud_id" id="solicitud_id" name="solicitud_id" type="text" class="form-control"></div>
			<label for="garantia_id" class="col-form-label col-sm-1">Garantia ID</label>
			<div class="col-sm-2"><input v-model="info_garantia.garantia_id" id="garantia_id" name="garantia_id" type="text" class="form-control"></div>
			<div class="col-sm-2"><button class="btn secondary-btn" @click="actualizar_garantia_solicitud">Actualizar</button></div>
		</div>
		<div class="row mb-4">
			<div class="col-sm-3 offset-sm-5"><button class="btn principal-btn" @click="importar_imagenes" :disabled="garantia.importar_imagenes || !garantia.garantia_id || !garantia.solicitud_id">Importar imágenes (Modelo de imágenes)</button></div>
			<div class="col-sm-2"><button class="btn secondary-btn" @click="imagen_modal=true">Cargar imagen</button></div>
			<div class="col-sm-2"><button class="btn warning-btn" @click="descargar_imanges">Recargar imágenes</button></div>
		</div>

		<div class="row form-group">
			<div v-for="(tmp_imagen) in imagenes" :key="tmp_imagen.id" class="col-sm-2 mb-4 image-box text-center">
				<img :src="tmp_imagen.data" alt="">
				<span class="box box-show" :title="tmp_imagen.estatus == 1 ? 'Ocultar imagen' : 'Mostrar imagen'" @click="cambiar_visibilidad_imagen(tmp_imagen)"><i v-if="tmp_imagen.estatus == 1" class="gg-eye"></i><i v-else class="gg-eye-alt"></i></span>
				<span class="box box-edit" title="Editar imágen" @click="editar_imagen(tmp_imagen)"><i class="gg-pen"></i></span>
				<span class="box box-delete" title="Eliminar imágen" @click="imagen_id = tmp_imagen.id"><i class="gg-trash-empty"></i></span>
				<span class="box-descript">{{ tmp_imagen.descripcion.length > 15 ? tmp_imagen.descripcion.substr(0,15)+'...' : tmp_imagen.descripcion }}</span>
			</div>
		</div>

		<Modal v-show="imagen_modal" :options="{width: '60vw',close: true}" @close="cancelar_carga">
			<div class="title">Imágen</div>
			<div class="body">
				<template v-if="!imagen">
					<div class="row form-group">
						<label for="" class="col-form-label col-sm-2">Imágen</label>
						<div class="col-sm-10">
							<div v-if="!file" ref="file_box" class="load_file div-table" @click="abrir_modal_imagen">
								<div class="div-cell-text">
									Arrastra o da click aquí para cargar una imágen
								</div>
							</div>
							<div v-else class="text-center file_preview">
								<img class="file_preview_img" :src="image_file" alt="">
								<div class="file_preview_info">
									<span class="info">Ancho: {{ info.width }}px | Alto: {{ info.height }}px | Peso: {{ Math.round(info.size/1000) }}kb</span>
									<span class="trash" title="Cambiar imagen" @click="file=null"><i class="gg-trash-empty"></i></span>	
								</div>
							</div>
						</div>
					</div>
					<div class="row form-group">
						<label for="imagen-descripcion" class="col-form-label col-sm-2">Descripción</label>
						<div class="col-sm-10">
							<input v-model="descripcion" type="text" class="form-control" name="imagen-descripcion" id="imagen-descripcion">
						</div>
					</div>
					<div class="row form-group">
						<label for="imagen-visible" class="col-form-label col-sm-2">Visible</label>
						<div class="col-sm-10">
							<VSwitch v-model="visible" texton="Sí" textoff="No" id="imagen-visible" name="imagen-visible" />
						</div>
					</div>
					<input v-show="false" type="file" ref="file_input" id="file_input" name="file_input" @change="agregar_imagen">
				</template>

				<template v-else >
					<div class="row form-group ">
						<label for="" class="col-form-label col-sm-2">Imágen</label>
						<div class="col-sm-10">
							<div class="text-center file_preview">
								<img class="file_preview_img" :src="imagen.data" alt="">
							</div>
						</div>
					</div>
					<div class="row form-group">
						<label for="imagen-descripcion" class="col-form-label col-sm-2">Descripción</label>
						<div class="col-sm-10">
							<input v-model="imagen.descripcion" type="text" class="form-control" name="imagen-descripcion" id="imagen-descripcion">
						</div>
					</div>
					<div class="row form-group">
						<label for="imagen-visible" class="col-form-label col-sm-2">Visible</label>
						<div class="col-sm-10">
							<VSwitch v-model="imagen.estatus" texton="Sí" textoff="No" id="imagen-visible" name="imagen-visible" />
						</div>
					</div>
				</template>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-2 offset-sm-8"><button class="btn secondary-btn" @click="cargar_imagen">{{ !imagen ? 'Cargar' : 'Guardar cambios' }}</button></div>
					<div class="col-sm-2"><button class="btn danger-btn" @click="cancelar_carga">Cancelar</button></div>
				</div>
			</div>
		</Modal>

		<Modal v-if="imagen_id" :options="{width:'40vw',type:'danger'}">
			<div class="title">Eliminar imágen</div>
			<div class="body">
				<p class="text-center mt-4 mb-4">Eliminarás la imágen seleccionada, esta acción no se puede revertir<br/><strong>¿Deseas continuar?</strong></p>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-2 offset-sm-8"><button class="btn secondary-btn" @click="eliminar_imagen">Eliminar</button></div>
					<div class="col-sm-2"><button class="btn danger-btn" @click="imagen_id=null">Cancelar</button></div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import Modal from '@/components/Modal'
	import VSwitch from '@/components/Form/Switch'

	import api from '@/apps/garantias/api/portal'

	export default {
		components: {
			Modal, VSwitch
		}
		,props: {
			garantia: null
		}
		,data: function() {
			return {
				file: null
				,info: {
					width: '0px'
					,height: '0px'
					,size: 0
				}
				,descripcion: null
				,visible: 1
				,imagen: null
				,imagen_modal: false
				,imagenes: []
				,imagen_id: null
				,info_garantia: {
					solicitud_id: null
					,garantia_id: null
				}
			}
		}
		,mounted: function() {

			['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(function(event) {
				this.$refs.file_box.addEventListener(event, function(e) {
					e.preventDefault();
					e.stopPropagation();
				}.bind(this), false);
			}.bind(this));

			this.$refs.file_box.addEventListener('drop', function(e) {
				if (e.dataTransfer.files.length > 0)
					this.file = e.dataTransfer.files[0];
			}.bind(this));

			this.info_garantia.solicitud_id = this.garantia.solicitud_id;
			this.info_garantia.garantia_id = this.garantia.garantia_id;

			this.descargar_imanges();
		}
		,methods: {
			cancelar_carga: function() {
				this.file = null;
				this.info = {
					width: '0px'
					,height: '0px'
					,size: 0
				}
				this.descripcion = null;
				this.visible = 1;
				this.imagen_modal = false;
				this.imagen = null;
			}
			,cargar_imagen: async function() {
				try {
					if (!this.imagen) {
						if(!this.descripcion || this.descripcion.length == 0)
							return this.$helper.showMessage('Error','Tienes que especificar una descripción', 'error','alert');

						if (this.file === null)
							return this.$helper.showMessage('Error','Tienes que especificar una imágen', 'error','alert');

						let formData = new FormData();
						formData.append('imagen',this.file);
						formData.append('descripcion', this.descripcion);
						formData.append('estatus', this.visible);
						formData.append('fideicomiso', this.garantia.fideicomiso.no_fideicomiso);

						await api.cargar_imagen(this.garantia.id, formData);
					}else {
						if (!this.imagen.descripcion || this.imagen.descripcion.length == 0)
							return this.$helper.showMessage('Error','Tienes que especificar una descripción2', 'error','alert');

						await api.editar_imagen(this.garantia.id, {descripcion: this.imagen.descripcion, estatus: this.imagen.estatus});
					}

					this.cancelar_carga();
					this.$emit('update');
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,descargar_imanges: async function() {
				this.imagenes = [];
				this.garantia.imagenes.forEach(async imagen => {
					try {
						let res = (await api.descargar_imagen(imagen.id)).data;

						imagen.data = URL.createObjectURL(res);
						this.imagenes.push(imagen);
					}catch(e) {
						this.$log.info('error',e);
						this.$helper.showAxiosError(e,'Error');
					}
				});
			}
			,editar_imagen: function(imagen) {
				this.imagen = imagen;
				this.imagen_modal = true;
			}
			,eliminar_imagen: async function() {
				try {
					await api.eliminar_imagen(this.imagen_id);
					this.imagen_id = null;

					this.cancelar_carga();
					this.$emit('update');
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,cambiar_visibilidad_imagen: async function(imagen) {
				try {
					await api.editar_imagen(imagen.id, {estatus: (imagen.estatus == 1 ? 0 : 1)});
					this.$emit('update');
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,importar_imagenes: async function() {
				try {
					let res = (await api.importar_imagenes(this.garantia.id)).data;
					this.$log.info('res', res);

					this.$emit('update');
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,abrir_modal_imagen: function() {
				this.$refs.file_input.click();
			}
			,agregar_imagen: function() {
				if (this.$refs.file_input.files.length > 0)	
					this.file = this.$refs.file_input.files[0];
			}
			,actualizar_garantia_solicitud: function() {
				this.$emit('update_info',{garantia_id: this.info_garantia.garantia_id, solicitud_id: this.info_garantia.solicitud_id});
			}
		}
		,computed: {
			image_file: function() {
				if (this.file)
					return URL.createObjectURL(this.file);

				return 'https://via.placeholder.com/250x200.png';
			}
			,get_image_width: function() {

				this.$log.info('width',width);
			}
		}
		,watch: {
			file: function(val) {
				if (this.file) {
					let img = new Image();
					let vue = this;
					img.onload = (e) => {
						vue.info.width = e.path[0].width;
						vue.info.height = e.path[0].height;
						vue.$log.info('img',e);
					}
					img.src = URL.createObjectURL(this.file);
					this.info.size = this.file.size;
				}
			}
			,garantia: function() {
				this.$log.info('Descargamos las imagenes',this.garantia.imagenes.length);
				this.descargar_imanges();

				this.$log.info('imagenes descargadas');
			}
		}
	}
</script>

<style lang="scss">
	.image-box {
		position: relative;
		overflow: hidden;

		.box {
			width: 30px;
			height: 36px;
			background-color: #CCCCCC;
			border: solid 1px #999999;
			padding: 10px;
			cursor:pointer;
			position:absolute;
			top: 9px;
		}

		img {
			max-width: 100%;
			max-height: 100%
		}

		.box-show {
			width: 40px;
			padding-top: 7px;
			padding-left: 7px;
			right: 95px;
		}

		.box-edit {
			right: 60px;

			i {
				margin-top: 4px;
			}
		}

		.box-delete {
			right: 23px;
		}

		.box-descript {
			width: 80%;
			background-color: #CCCCCC;
			border: solid 1px #999999;
			position: absolute;
			bottom: 9px !important;
			left: 27px;
		}
	}

	.load_file {
		width: 100%;
		min-height: 250px;
		// background-color: #E6E6E6;
		border: dashed 3px #E6E6E6;
		color: #808080;
	}

	.div-table {
		width: 100%;
		height: 100%;
		display: table;

		.div-cell-text{
			text-align: center;
			vertical-align: middle;
			display: table-cell;
		}

		.div-cell-img {
			width: 100%;
			height: 100%;
			vertical-align: middle;
			display: inline-block;
			text-align: center;

			img {
				vertical-align: middle;
			}
		}
	}

	.load_file_preview {
		max-width: 100%;
		max-height: 100%;
	}

	.file_preview {
		height: 300px;
		padding: 5px;
		border: dashed 3px #E6E6E6;
		position: relative;

		.file_preview_img {
			height: 100%;
		}

		.file_preview_info {
			background-color: #E6E6E6;
			padding: 5px 10px;
			position: absolute;
			bottom: 5px;
			right: 5px;

			span {
				display:inline-block;

				i {
					margin-top: 10px;
					margin-left: 10px;
				}
			}
		}
	}
</style>